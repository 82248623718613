import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Navbar from './Navbar';
import Home from './Home';
import PreviousEditions from './PreviousEditions';
import Submit from './Submit';
import GetInvolved from './GetInvolved';
import About from './About';
import Footer from './Footer';
import dots from './assets/dots.png';
import PageNotFound from './PageNotFound';

const App = () => {
  return (
    <div className="flex flex-col justify-start items-center bg-tvpink min-h-screen h-full w-screen p-4 relative">
      <Router>
        <div className="nav-container m-4 w-10/12 sm:mb-6 md:mb-7 lg:mb-10 xl:mb-12 2xl:mb-20 z-20">
            <Navbar />
        </div>
        <div className='flex flex-col items-start w-10/12 sm:w-9/12 z-10'>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/previous-editions" element={<PreviousEditions />} />
            <Route path="/submit" element={<Submit />} />
            <Route path="/get-involved" element={<GetInvolved />} />
            <Route path="/about" element={<About />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </div>
        <div className="footer-container mt-8 md:mt-12 lg:mt-16 w-full flex-grow flex flex-col justify-end z-10">
          <Footer/>
        </div>
      </Router>
      <img src={dots} className="absolute left-0 top-0 -ml-6 -mt-10 h-36 sm:h-44 md:h-52 md:-ml-8 lg:h-60 lg:-mt-12 xl:h-80 xl:-mt-14 xl:-ml-14 2xl:h-96 2xl:-ml-16 2xl:-mt-16 z-0" alt=""/>
    </div>
  );
};

export default App;
