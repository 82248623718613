import { NavLink } from 'react-router-dom';
import { TiStarFullOutline } from "react-icons/ti";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdClose } from "react-icons/md";
import { useState } from 'react';

//import logo from './assets/twelvevacancies_logo.png';
import logo from './assets/twelvevacancies_poster.png';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNav = () => {
    setIsOpen(!isOpen);
  }

  return (
    <nav>
      <div className="desktop-navbar hidden sm:flex sm:flex-col justify-center items-center pb-1 sm:gap-2 lg:gap-4 xl:gap-6">
        <a href="/" className="">
          <img src={logo} alt="logo" className="sm:h-16 lg:h-20 xl:h-24 2xl:h-28 3xl:h-32 4xl:h-40" />
        </a>
        <div className={`navbar hidden justify-between px-2 py-1  bg-tvpink
                        sm:relative sm:flex sm:flex-row sm:items-center sm:border-y-2 sm:border-y-tvred
                        sm:w-full 2xl:border-y-4 4xl:border-y-8`}>
          <ul className='nav-items flex flex-col justify-between items-start gap-2 w-full font-alice font-bold text-white text-sm 
                        sm:flex-row sm:items-center sm:w-4/5 md:w-3/4 sm:text-xs md:text-sm lg:text-base xl:text-xl 2xl:text-2xl 3xl:text-3xl 4xl:text-4xl' onClick={toggleNav}>
            <li className="w-full border-b border-tvpurple sm:border-0 sm:w-fit">
              <NavLink to="/" className={({ isActive }) => isActive ? "text-tvred" : "text-white block"}>HOME</NavLink>
            </li>
            <li className="w-full border-b border-tvpurple sm:border-0 sm:w-fit">
              <NavLink to="/previous-editions" className={({ isActive }) => isActive ? "text-tvred" : "text-white block"}>PREVIOUS EDITIONS</NavLink>
            </li>
            <li className="w-full border-b border-tvpurple sm:border-0 sm:w-fit">
              <NavLink to="/submit" className={({ isActive }) => isActive ? "text-tvred" : "text-white block"}>SUBMIT</NavLink>
            </li>
            <li className="w-full border-b border-tvpurple sm:border-0 sm:w-fit ">
              <NavLink to="/get-involved" className={({ isActive }) => isActive ? "text-tvred" : "text-white block"}>GET INVOLVED</NavLink>
            </li>
            <li className="w-full border-b border-tvpurple sm:border-0 sm:w-fit">
              <NavLink to="/about" className={({ isActive }) => isActive ? "text-tvred" : "text-white block"}>ABOUT</NavLink>
            </li>
          </ul>
          <div className="flex flex-row justify-end sm:w-1/5 md:w-1/4">
            <TiStarFullOutline className="hidden text-white text-sm h-4  sm:block sm:h-6 sm:w-6 lg:h-8 lg:w-8 xl:h-10 xl:w-10 2xl:h-10 2xl:w-10 3xl:h-12 3xl:w-12 4xl:h-16 4xl:w-16"/>
          </div>
        </div>
      </div>
      <div className="mobile-navbar flex flex-row justify-center items-center border-b-2 border-tvred pb-1 relative sm:hidden">
        <div className="w-1/3">
        </div>
        <a href="/" className="flex flex-row items-cetner justify-center w-1/3">
          <img src={logo} alt="logo" className="h-10 w-auto max-w-full sm:hidden" />
        </a>
        <div className="flex flex-row justify-end items-center w-1/3 relative">
          <button onClick={toggleNav}>
            {isOpen ? <MdClose className="text-white h-9 w-9 " /> : <GiHamburgerMenu className="text-white h-8 w-8 "/>}
          </button>
          
        </div>
        <div className={`navbar-dropdown flex flex-col justify-between items-start border-0 shadow-xl border-tvred  px-2 py-1 w-1/2 bg-tvpink
                  top-full right-0 mt-1 ${isOpen ? 'flex absolute' : 'hidden'}`}>
            <ul className='nav-items flex flex-col justify-between items-start gap-2 w-full font-alice font-bold text-white text-sm' onClick={toggleNav}>
              <li className="w-full border-b border-tvpurple">
                <NavLink to="/" className={({ isActive }) => isActive ? "text-tvred" : "text-white block"}>HOME</NavLink>
              </li>
              <li className="w-full border-b border-tvpurple">
                <NavLink to="/previous-editions" className={({ isActive }) => isActive ? "text-tvred" : "text-white block"}>PREVIOUS EDITIONS</NavLink>
              </li>
              <li className="w-full border-b border-tvpurple">
                <NavLink to="/submit" className={({ isActive }) => isActive ? "text-tvred" : "text-white block"}>SUBMIT</NavLink>
              </li>
              <li className="w-full border-b border-tvpurple">
                <NavLink to="/get-involved" className={({ isActive }) => isActive ? "text-tvred" : "text-white block"}>GET INVOLVED</NavLink>
              </li>
              <li className="w-full border-b border-tvpurple">
                <NavLink to="/about" className={({ isActive }) => isActive ? "text-tvred" : "text-white block"}>ABOUT</NavLink>
              </li>
            </ul>
          </div>
      </div>
    </nav>
  );
}

export default Navbar;  