import sixtymixty from './assets/films/fall2024/sixtymixty.png';
import convenience from './assets/films/fall2024/24_7.png';
import tuckerspuppets from './assets/films/fall2024/tuckerspuppets.png';
import inmysleep from './assets/films/fall2024/inmysleep.png';
import playalong from './assets/films/fall2024/playalong.png';
import unidonkey from './assets/films/fall2024/unidonkey.png';
import look from './assets/films/fall2024/look.png';
import lighthead from './assets/films/fall2024/lighthead.png';
import thedeathinsleep from './assets/films/fall2024/thedeathinsleep.png';
import overthehatch from './assets/films/fall2024/overthehatch.png';
import housefilm1 from './assets/films/fall2024/housefilm1.png';
import antdetective from './assets/films/fall2024/antdetective.png';

const Home = () => {
  const films = [
    {
      title: "SIXTY MIXTY",
      director: "Nevin Louie",
      description: (<p>60 people in 60 seconds... there's so much to see!</p>),
      credits: (<p>
        Nevin Louie is a Montreal based filmmaker who grew up in North Vancouver, 
        BC and is currently studying film production at Concordia University. 
        He is committed to telling an epically authentic story by embracing what's 
        right in front of him. He also loves to laugh. Haha! His achievements 
        include an official 2024 selection at Fantasia Festival for <i>"I Didn't 
        Sleep Last Night" and two year consecutive winner of Best Overall for 
        "A Happy Ending"</i> (2020) and <i>"Getting Gizmo"</i> (2021) in the Senior 
        Category for the 48 hour Zoom Film Festival. Nevin's online portfolio 
        can be found at <a href="https://www.nevinlouie.com" target="_blank" rel="noreferrer" className="underline">www.nevinlouie.com</a>.
      </p>),
      award: "",//"WINNER: FAN FAVOURITE AWARD",
      image: sixtymixty
    }, 
    {
      title: "24/7 CONVENIENCE",
      director: "Jude Gross",
      description: (<p>
        A staging of the common practice but intrinsically strange experience of
         talking to someone at a convenience store.
      </p>),
      credits: (<p>
        Jude Gross is an animator, video editor and filmmaker based in Vancouver, 
        British Columbia. Their work is characterized by its fabulistic visual 
        style, and incorporates digital mixed media, collage, analogue textures 
        and traditional 2D character animation. Their animated films
        include <i>24/7 Convenience</i> (2024) and <i>Office Party</i> (2023).
      </p>),
      image: convenience
    },
    {
      title: "TUCKER'S PUPPETS",
      director: "Jaida Sanada",
      description: (<p>
        A dysfunctional family explores their deepest fears which then actualise 
        through a spooky puppet show!
      </p>),
      credits: (<p>
        Director Jaida Sanada is a Japanese and Canadian filmmaker based in 
        Vancouver. Currently enrolled at the University of British Columbia, 
        she is majoring in Media Studies and aspires to pursue a career in 
        film and television. Her journey started with narrative productions 
        in high school; assistant directing plays and musicals, however later 
        shifted to directing ultra shorts in her own time. Now, her debut 
        project, <i>Tucker's Puppets</i>, represents a significant milestone in her 
        artistic journey and she is thrilled to be sharing this with you today!
      </p>),
      image: tuckerspuppets
    },
    {
      title: "IN MY SLEEP, I SWALLOW YOU",
      director: "Sidonie Auclair-Gendron",
      description: (<p>
        A flourishing world exists deep in the guts.
      </p>),
      credits: (<p>
        Sidonie Auclair-Gendron is currently studying film animation at 
        Concordia University (Mel Hoppenheim School of Cinema). Previously in 
        a visual arts program, she loves animating textures and using techniques 
        that she borrows from traditional art practices.
      </p>),
      image: inmysleep
    },
    {
      title: "PLAY ALONG",
      director: "Esandi Amarakoon",
      description: (<p>
        <i>“Play Along”</i> is a surreal multimedia short film that explores the 
        sensations of feeling unsafe at home. Told through the lens of a child, 
        the audience learns about the unique experience of navigating feelings of 
        unease with a parent.
      </p>),
      credits: (<p>
        Esandi Amarakoon is a filmmaker based in Montreal, Quebec and a recent 
        graduate from Concordia University’s Film Animation program. With a deep 
        appreciation for tactile creation, She enjoys working with her hands and 
        exploring various mediums. These include painting, collage, and fiber/wearable 
        arts. These multidisciplinary talents are reflected in her animations, 
        where she specializes in stop motion and sound design. Gravitating towards 
        the surreal, Esandi’s artistic vision invites viewers into extraordinary 
        worlds. Her work explores sensory and visceral themes in order to delve 
        into the complex range of human emotions.
      </p>),
      image: playalong
    },
    {
      title: "UNIDONKEY AND RABBIT MOVE TO CANDYLAND",
      director: "Imge Su Basmaci",
      description: (<p>
        An optimistic donkey with a glued-on horn and its slimy bunny companion 
        embark on a journey of their dreams, only to confront the disillusionment 
        behind the facade of Candyland.
      </p>),
      credits: (<p>
        Imge Su Basmaci is a multimedia artist from Ankara, Turkiye, whose work 
        explores folklore, authority and performance of self, intertwining these 
        themes with an unsettling aesthetic. Recently graduating from NSCAD University, 
        Basmaci employs a blend of painting, illustration, and paper sculpture to evoke 
        narratives that provoke discomfort and wonder. Basmaci’s artistic practice is 
        characterized by whimsical settings and an unusual color palette that draws 
        viewers into a world where the familiar becomes uncanny. As a storyteller at 
        heart, Basmaci speaks to the collective anxieties of existing within fragile 
        social identities and an eerie experience of dysfunction.
      </p>),
      image: unidonkey
    },
    {
      title: "LOOK",
      director: "Georgia Acken",
      description: (<p>
        A young girl haunted by loneliness makes friends in the unlikeliest of places.
      </p>),
      credits: (<p>
        Georgia is a 17-year-old actor and director from BC Canada who has found herself,
        and her passion, in film. Georgia started acting when she was 10, and plans to 
        continue performing, creating, and learning for the rest of her life. 
        <i>LOOK</i> is her first short film and her first time directing and writing. 
        She is deeply inspired by the amazing directors she has worked with, and strives 
        for authenticity and detail in her work.
      </p>),
      image: look
    },
    {
      title: "LIGHTHEAD",
      director: "Kit Baronas",
      description: (<p>
        After receiving a hickey from his boyfriend, a closeted teen must hide it from 
        his hyper-masculine best friend as they enter a cat and mouse game through their 
        abandoned high school.
      </p>),
      credits: (<p>
        Christopher “Kit” Baronas is a writer/director born and raised on the 
        unceded traditional territories of the xʷməθkʷəy̓əm (Musqueam), 
        Sḵwx̱wú7mesh (Squamish), and səlilwətaɬ (Tsleil-Waututh) Nations. 
        Otherwise known as "Vancouver." Kit is interested in stories centered 
        around dramatical concepts with elements of unease. Often creating from 
        his own life experiences. His films have played in festivals around the 
        world such as Montréal’s Festival du Nouveau Cinéma and Seattle’s 
        National Film Festival for Talented Youth. Kit is an alumnus of the 
        University of British Columbia's competitive Film Production Program 
        and has been mentored by emerging filmmakers such as Antoine Bourges 
        and Igor Djrlaca. He is currently set to release his next project 
        <i>“The Lame Shall Sing”</i>, a dark comedy based on his experiences growing 
        up in a Catholic community.
      </p>),
      image: lighthead
    },
    {
      title: "THE DEATH IN SLEEP",
      director: "Mira De Koven",
      description: (<p>
        A skeleton overtaken by the forest begins to breathe. It then starts to 
        decompose, in reverse.
      </p>),
      credits: (<p>
        Mira De Koven is a mixed-media animator and director. They’re inspired 
        by mundane miracles and enjoy deconstructing them into the absurd. The 
        stories De Koven tells feature nonbinary and multiracial characters 
        with a visual a mix of digital, painted, and collaged backdrops. Their 
        influences are a contrasting blend of slice-of-life storytelling, 
        visually compelling horror and brightly coloured psychedelic animation.
      </p>),
      image: thedeathinsleep
    },
    {
      title: "OVER THE HATCH",
      director: "Nevin Louie",
      description: (<p>
        Three boys on a quest to gain rooftop access in their new home. A 
        shockingly true story.
      </p>),
      credits: (<p>
        Nevin Louie is a Montreal based filmmaker who grew up in North Vancouver, 
        BC and is currently studying film production at Concordia University. 
        He is committed to telling an epically authentic story by embracing what's 
        right in front of him. He also loves to laugh. Haha! His achievements 
        include an official 2024 selection at Fantasia Festival for <i>"I Didn't 
        Sleep Last Night" and two year consecutive winner of Best Overall for 
        "A Happy Ending"</i> (2020) and <i>"Getting Gizmo"</i> (2021) in the Senior 
        Category for the 48 hour Zoom Film Festival. Nevin's online portfolio 
        can be found at <a href="https://www.nevinlouie.com" target="_blank" rel="noreferrer" className="underline">www.nevinlouie.com</a>.
      </p>),
      image: overthehatch
    },
    {
      title: "(housefilm1)",
      director: "Charlie Galea McClure",
      description: (<p>
        Combining hand-made animation with 3D compositing, <i>(housefilm1)</i> explores 
        the House as a multi-dimensional vessel where dreams and memories 
        intersect and overlap.
      </p>),
      credits: (<p>
        Charlie is an experimental animator with a passion for horror, 
        surrealism, and DIY aesthetics based in Tiohtià:ke/ Montreal, Q.C. 
        Currently completing a BFA in Film Animation at Concordia University, 
        Charlie is interested in alternative forms of animation- a mixed-media 
        mélange of under-camera, stop-motion, and digital compositing techniques.
      </p>),
      image: housefilm1
    },
    {
      title: "ANT DETECTIVE",
      director: "Quinn Funk",
      description: (<p>
        For weeks, dozens of ants have been burning alive in Ant City. The 
        direness of the situation calls for Antoine Weaver, 
        the Ant Detective, to solve the case and bring the killer to justice.
      </p>),
      credits: (<p>
        Quinn Funk is a 20 year old Canadian filmmaker, actor, animator, 
        writer, and editor from Beaumont, Alberta. This is his first screening 
        at a film festival! He is currently attending the Film Production 
        program at UBC in Vancouver. He has been making mixed media animations 
        and live action comedy short films since elementary school. His smaller 
        projects tend to be a one man show, with Quinn filling almost every 
        role in production and getting help from friends and family for music 
        composition and additional actors.
      </p>),
      image: antdetective
    }
  ];

  return (
    <div className="flex flex-col gap-2 sm:gap-3">
      <div className="home-heading">
        <h1 className="text-2xl font-bold sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl lg:mb-3">FALL 2024</h1>
      </div>
      <div className="films flex flex-col gap-8 lg:gap-12">
        {films.map(film => {
          return (
          <div className="film-card flex flex-row justify-between items-start sm:gap-5 md:gap-6 lg:gap-12 2xl:gap-24">
            <div className="flex flex-col gap-2 sm:w-1/2">
              <div className="flex flex-col">
                <h3 className="font-sans font-bold text-base sm:text-sm md:text-base lg:text-lg xl:text-2xl 2xl:text-3xl"><i>{film.title}</i> </h3>
                <h4 className="font-bold text-sm sm:text-xs sm:max-w-full md:text-sm lg:text-base xl:text-lg 2xl:text-xl">By {film.director}</h4>
              </div>
              { film.award ?
                <div className="bg-tvpurple text-sm p-2 my-1 w-max sm:text-xs sm:px-1 md:px-2 md:mb-4 md:text-sm lg:text-base xl:text-xl 2xl:text-2xl 2xl:p-3">
                  <h4>{film?.award}</h4>
                </div>
              : <></>
              }
              <div className="w-full max-w-80 sm:hidden">
                <img src={film.image} alt={`still from the film ${film.title}`} className=""/>
              </div>  
              <div className="film-desc flex flex-col max-w-80 text-sm gap-2 sm:text-xs sm:max-w-full md:text-sm lg:text-base xl:text-lg 2xl:text-xl">
                {
                  film.description
                }
                {
                  film.credits
                }
              </div>
            </div>
            <div className="hidden sm:block sm:w-1/2 sm:mt-1">
                <img src={film.image} alt={`still from the film ${film.title}`}/>
            </div>  
          </div>
          )
        })
      }
      </div>
    </div>
  );
}

export default Home;